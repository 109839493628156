<template>
    <div v-show="show">
        <transition name="modal_fade">
            <div class="modal_backdrop">
                <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
    
                    <div class="modal_header">
                        {{heading}}
                        <button type="button" class="modal_btn_close" @click="close(false)" aria-label="Close modal"><span class="glyphicon glyphicon-remove"></span></button>
                    </div>
    
                    <div class="modal_body">{{body}}</div>
    
                    <div class="modal_footer">
                        <button type="button" class="btn btn-primary" @click="close(value)" aria-label="Close modal">Yes</button>
                        <button type="button" class="btn btn-default" @click="close(false)" aria-label="Close modal">No</button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Confirm",
    data() {
        return {
            show: false,
            value: false,
        }
    },
    props: ['heading', 'body'],
    methods: {
        open: function(value) {
            this.show = true;
            this.value = value;
        },
        close: function (value) {
            this.$emit('response', value);
            this.show = false;
            this.value = false;
        }
    }
}
</script>

<style scoped>

</style>