export default class CanvasPainter {
	constructor(canvasDomID = null) {
		this.setDomID(canvasDomID);
	}
	
	setDomID(ID) {
		this.canvas_dom_id = ID;
	}
	
	getContext = function () {
		let canvas = document.getElementById(this.canvas_dom_id);
		let width = canvas.parentElement.clientWidth;
		canvas.width = width;
		canvas.height = (width * 0.65);
		
		if (canvas.getContext) {
			let ctx = canvas.getContext('2d');
			let scale = (width / 944);
			ctx.scale(scale, scale);
			ctx.translate(0.5, 0.5);
			
			return ctx;
		}
		
		return null;
	}
	
	drawLine = function (ctx, x, y, x_length, y_length, arrows) {
		if (arrows) {
			ctx.strokeStyle = '#999999';
		} else {
			ctx.strokeStyle = '#000000';
		}
		
		ctx.beginPath();
		ctx.moveTo(x, y);
		ctx.lineTo((x + x_length), (y + y_length));
		ctx.closePath();
		ctx.stroke();
		
		if (arrows) {
			if (y_length) { // vertical line
				this.drawTriangle(ctx, x, y, 'u');
				this.drawTriangle(ctx, x, (y + y_length), 'd');
			} else { // horizontal line
				this.drawTriangle(ctx, x, y, 'l');
				this.drawTriangle(ctx, (x + x_length), y, 'r');
			}
		}
	}
	
	drawTriangle = function (ctx, x, y, type) {
		let c1 = {
			'x': x,
			'y': y
		};
		let c2 = {};
		let c3 = {};
		
		// type: u = up, r = right, d = down, l = left
		if (type == 'l') {
			c2.x = (x + 5);
			c2.y = (y - 5);
			c3.x = (x + 5);
			c3.y = (y + 5);
		} else if (type == 'r') {
			c2.x = (x - 5);
			c2.y = (y - 5);
			c3.x = (x - 5);
			c3.y = (y + 5);
		} else if (type == 'u') {
			c2.x = (x - 5);
			c2.y = (y + 5);
			c3.x = (x + 5);
			c3.y = (y + 5);
		} else if (type == 'd') {
			c2.x = (x - 5);
			c2.y = (y - 5);
			c3.x = (x + 5);
			c3.y = (y - 5);
		}
		
		ctx.fillStyle = "#000000";
		ctx.beginPath();
		ctx.moveTo(c1.x, c1.y);
		ctx.lineTo(c2.x, c2.y);
		ctx.lineTo(c3.x, c3.y);
		ctx.closePath();
		ctx.fill();
	}
	
	lineLabel = function (ctx, label, x, y, color, stroke_color) {
		ctx.font = '20px Open Sans';
		ctx.fillStyle = color;
		let text = ctx.measureText(label);
		let box_width = (text.width + 10);
		x = (x - (box_width / 2));
		
		ctx.fillRect(x, y, box_width, 30);
		ctx.fillStyle = "#000000";
		ctx.fillText(label, (x + 5), (y + 22));
		
		if (stroke_color) {
			ctx.strokeStyle = stroke_color;
			ctx.strokeRect(x, y, box_width, 30);
			ctx.stroke_style = '#000000';
		}
	}
	
	drawBoxes = function (ctx, x, y, w, h) {
		// draw filled existing unit
		ctx.fillStyle = '#f5f5f5';
		ctx.fillRect(x, y, w, h);
		
		// outline existing unit
		ctx.lineWidth = 3;
		ctx.strokeRect(x + 1, y + 1, w - 2, h - 2);
	}
}
